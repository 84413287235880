import { range, find, shuffle } from 'lodash'

import { now } from './dayjs'

const applications = (app) => {
  const normals = app.stimulus.map(stimulu => {
    return {...stimulu, type: '1', status: null};
  });

  const probes = app.stimulus.map(stimulu => {
    return {...stimulu, type: '2', status: null};
  });

  const usuals = range(app.attempts || 1).reduce(accs => {
    return [...accs, ...(app.randomize ? shuffle(normals) : normals)];
  }, []);

  if (app.probe) {
    return [...probes, ...usuals, ...probes];
  }

  return [...usuals];
}

export const set = (name, data) => {
  localStorage.setItem(name, JSON.stringify(data))

  return data
}

export const get = (name) => {
  const item = localStorage.getItem(name)

  if (item !== 'undefined') {
    return JSON.parse(item)
  }

  return null
}

export const remove = (name) => {
  return localStorage.removeItem(name)
}

export const clear = () => {
  return localStorage.clear()
}

export const existsAttendance = (id) => {
  return new Promise(resolve => {
    resolve(storeAttendances().some(attendance => attendance.student.id === id))
  })
}

export const storeAttendance = (data) => {
  return new Promise((resolve) => {
    const attendances = storeAttendances()

    existsAttendance(data.student.id)
      .then(exists => {
        if (!exists) {
          const attendance = {
            start: now(),
            student: {
              id: data.student.id,
              name: data.student.name,
              avatar: data.student.avatar,
              initials: data.student.initials,
            },
            programs: data.programs.map(item => ({
              id: item.id,
              name: item.name,
              attempts: item.attempts,
              fase: item.fase,
              faseName: item.faseName,
              help: item.help,
              helpName: item.helpName,
              delay: item.delay,
              status: item.status,
              finished: item.finished,
              comments: null,
              applications: applications(item)
            }))
          }
      
          attendances.push(attendance)
      
          set('attendances', attendances)
    
          resolve(attendance)
        }
      })
  });
}

export const rescueAllAttendances = (student) => {
  return storeAttendances().filter((item) => {
    return item.student.id === student.id
  })
}

export const rescueAttendance = (id) => {
  return new Promise(resolve => {
    resolve(find(storeAttendances(), item => {
      return parseInt(item.student.id, 10) === parseInt(id, 10)
    }))
  });
}

export const updateAttendance = (attendance) => {
  return new Promise((resolve) => {
    const attendances = storeAttendances().map(item => {
      if (item && item.student.id === attendance.student.id) {
        item = attendance
      }
      return item
    })

    set('attendances', attendances)

    return resolve(attendance)
  });
}

export const discartAttendance = (attendance) => {
  return new Promise((resolve) => {
    const attendances = storeAttendances().filter(item => {
      if (item && item.student.id !== attendance.student.id) {
        return item
      }
    })

    set('attendances', attendances)

    return resolve(attendance)
  });
}

export const storeAttendances = () => {
  return get('attendances') || []
}

export const updateAuth = (data) => {
  const auth = get('auth')

  return set('auth', {
    token: auth.token,
    user: data,
  })
}

export const token = () => {
  const user = get('auth')

  return user && user.token || null
}

export const me = () => {
  const user = get('auth')

  return user && user || null
}

export default {
  set, get, remove, clear, token, me, updateAuth
}