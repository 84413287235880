import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { 
      customProperties: true 
    },
    themes: {
      light: {
        primary: '#490052',
        tertiary: '#ede6ee',
        quaternary: '#f7f2f8',
        white: '#ffffff',
        black: '#000000',
        grey: '#9e9e9e',
      },
    },
  },
});
