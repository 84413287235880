import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/pt-br'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

export const difference = (start, end, view = 'minute') => {
  return dayjs(end).diff(start, view)
}

export const formater = (date, format = 'DD/MM/YYYY') => {
  return dayjs(date).format(format)
}

export const dateAsTime = (date, format = 'DD/MM/YYYY [às] HH:mm') => {
  return dayjs(date).format(format)
}

export const onlyDateBR = (date, format = 'DD/MM/YYYY') => {
  return dayjs(date).format(format)
}

export const onlyHourBR = (date, format = 'HH:mm') => {
  return dayjs(date).format(format)
}

export const now = () => {
  return dayjs().format('YYYY-MM-DD HH:mm:ss')
}

export default dayjs