import Vue from 'vue'

import http from '../services/http'
import storage from '../services/storage'

Vue.mixin({
  methods: {
    http() {
      return http
    },
    me() {
      return storage.me()
    },
  },
})