import Vue from 'vue';
import App from './App.vue';

import vuetify from './plugins/vuetify';

import router from './router';
import store from './store';

import './plugins/mixins';
import './plugins/validation';
import './plugins/fragment';
import './plugins/components';
import './plugins/worker';
import './plugins/portal';
import './plugins/clipboard';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
