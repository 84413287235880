import axios from 'axios'

import StorageService from '../services/storage'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(config => {
  const token = StorageService.token()

  if (token) {
    config.headers['Authorization'] = `bearer ${token}`
  }
  
  return config;
}, error => {
  return Promise.reject(error);
})

http.interceptors.response.use(response => {
  return response.data;
}, error => {
  return Promise.reject(error.response.data);
})

export default http