export default {
  StartAttendenceAction(context, payload) {
    context.commit('startAttendence', payload)
  },
  StopAttendenceAction(context) {
    context.commit('startAttendence')
  },
  StoreAuthAction: (context, payload) => {
    return context.commit('storeAuth', payload)
  },
  UpdateAuthAction: (context, payload) => {
    return context.commit('updateAuth', payload)
  },
  RemoveAuthAction: (context) => {
    return context.commit('removeAuth')
  }
}