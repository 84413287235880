import StorageService from '../services/storage'

export default {
  startAttendence(state, payload) {
    state.attendence = payload
  },
  stopAttendence(state) {
    state.attendence = {}
  },
  storeAuth: (state, payload) => {
    state.auth = StorageService.set('auth', payload)
  },
  updateAuth: (state, payload) => {
    state.auth = StorageService.updateAuth(payload)
  },
  rescueAuth: () => {
    return StorageService.get('auth')
  },
  removeAuth: () => {
    return StorageService.remove('auth');
  }
}