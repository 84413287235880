import StorageService from '../services/storage'

export default {
  getStoreAuth: (state) => {
    return state.auth || StorageService.get('auth')
  },
  profile: (state) => {
    const auth = state.auth || StorageService.get('auth')
    
    return auth.user.profile
  }
}