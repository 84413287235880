import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import StorageService from '../services/storage'

const beforeEnter = (to, from, next) => {
  if (StorageService.token()) {
    return next()
  }
  
  window.location.href = 'auth/login'
}

const routes = [
  {
    path: '/',
    component: () => import('../layouts/Internal'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../pages/Home'),
        beforeEnter,
      },
      {
        path: '/coordenadores',
        name: 'coordinators',
        component: () => import('../pages/Coordinators'),
        beforeEnter,
      },
      {
        path: '/estudantes',
        name: 'students',
        component: () => import('../pages/Students'),
        beforeEnter,
      },
      {
        path: '/estudante',
        name: 'student',
        component: () => import('../pages/Student'),
        beforeEnter,
      },
      {
        path: '/acompanhantes',
        name: 'companions',
        component: () => import('../pages/Companions'),
        beforeEnter,
      },
      {
        path: '/aplicadores',
        name: 'therapists',
        component: () => import('../pages/Therapists'),
        beforeEnter,
      },
      {
        path: '/equipe',
        name: 'collaborators',
        component: () => import('../pages/Collaborators'),
        beforeEnter,
      },
      {
        path: '/configuracoes',
        name: 'configurations',
        component: () => import('../pages/Configurations'),
        beforeEnter,
      },
      {
        path: '/atendimento',
        name: 'attendance',
        component: () => import('../pages/Attendance'),
        beforeEnter,
      },
      {
        path: '/atendimento/programas',
        name: 'attendance.programs',
        component: () => import('../pages/AttendancePrograms'),
        props: ({params, query}) => ({
          student: params.student,
          programs: params.programs,
          id: query.id,
        }),
        beforeEnter,
      },
    ]
  },
  {
    path: '/auth',
    component: () => import('../layouts/External'),
    children: [
      {
        path: '/auth/login',
        name: 'auth.login',
        component: () => import('../pages/Login'),
      },
      // {
      //   path: '/auth/cadastro',
      //   name: 'auth.register',
      //   component: () => import('../pages/Register'),
      // },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
